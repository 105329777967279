(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("element-ui"));
	else if(typeof define === 'function' && define.amd)
		define(["element-ui"], factory);
	else if(typeof exports === 'object')
		exports["stateform-element"] = factory(require("element-ui"));
	else
		root["stateform-element"] = factory(root["element-ui"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__5f72__) {
return 